import React, { createContext, useContext, useState } from "react";

const WindowConfigContext = createContext();

const defaultConfig = {
  agentMeta: {
    type: null,
    agent: null,
    genericAgents: [],
    customAgents: [],
    owner: null
  },
  isAgent: null,
};

const WindowConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultConfig);

  const updateConfig = (newConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  };

  return (
    <WindowConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </WindowConfigContext.Provider>
  );
};

const useWindowConfig = () => {
  const context = useContext(WindowConfigContext);
  if (!context) {
    throw new Error("context is not being used properly");
  }
  return context;
};

export { WindowConfigProvider, useWindowConfig };
